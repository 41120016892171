export const EShomeVerticalsRouting = {
  account: '/area-privada',
  acquisitions: '/vender',
  sales: '/comprar',
  rent: '/alquilar',
  mortgage: '/hipotecas',
  salesBrokerage: '/vende-tu-casa',
} as const;

export const FRhomeVerticalsRouting = {
  account: '/espace-prive',
  acquisitions: '/vendre',
  sales: '/acheter',
  rent: '/louer',
  mortgage: '/hypotheques',
  salesBrokerage: '/gestion-des-ventes',
} as const;

export const PThomeVerticalsRouting = {
  account: '/area-privada',
  acquisitions: '/vender',
  sales: '/comprar',
  rent: '/aluguer',
  mortgage: '/hipotecas',
  salesBrokerage: '/gerenciamento-de-vendas',
} as const;

export const LegalPagesRouting = {
  ES: {
    buySell: 'terminos-y-condiciones-compraventa',
    rent: 'terminos-y-condiciones-alquiler',
    mortgageConditions: 'terminos-y-condiciones-hipotecas',
    saleManagement: 'terminos-y-condiciones-gestionventa',
    assetManagement: 'terminos-y-condiciones-asset-management',
    promotions: 'condiciones-aplicables-promociones',
    mortgagePrivacy: 'politica-de-privacidad-hipotecas',
    privacyPolicy: 'politica-de-privacidad',
    cookies: 'politica-de-cookies',
    legal: 'aviso-legal',
    ethicalChannel: 'politica-de-privacidad-canaletico',
    ethicalCode: 'codigo-etico',
  },
  PT: {
    buySell: 'termos-e-condicoes-compra-e-venda',
    rent: '',
    mortgageConditions: '',
    saleManagement: '',
    promotions: 'condicioes-da-promocao',
    mortgagePrivacy: '',
    privacyPolicy: 'politica-de-privacidade',
    cookies: 'politica-de-cookies',
    legal: 'aviso-legal',
    ethicalChannel: 'politica-de-privacidade-canaletico',
  },
  FR: {
    buySell: '',
    rent: '',
    mortgageConditions: '',
    saleManagement: '',
    promotions: '',
    mortgagePrivacy: '',
    privacyPolicy: 'politique-de-confidentialite',
    cookies: 'politique-relative-aux-cookies',
    legal: 'mentions-legales',
    ethicalChannel: 'politique-de-confidentialite-canal-ethique',
  },
} as const;

export const VirtualTourRouting = {
  ES: 'paseo-virtual',
  PT: 'visita-virtual',
  FR: '',
};
